import React, { useState } from 'react';
import { Button, LoaderOn, Modal, Space, openNotification, Input, Switch } from 'tt-ui-lib/core';
import { useMutation } from '@apollo/client';
import { BC_ADMIN_UPDATE_NET } from 'api';

const UpdateNetModal = ({ open, setOpen }) => {
  const [updateNet] = useMutation(BC_ADMIN_UPDATE_NET);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    netID: '',
    symbol: '',
    name: '',
    digits: '',
    rpc_ssl: null,
    rpc_host: '',
    rpc_port: null,
    rpc_path: '',
  });

  const sendUpdateNet = () => {
    setLoading(true);

    const payload = {
      netID: data.netID,
      symbol: data.symbol || undefined,
      name: data.name || undefined,
      digits: data.digits || undefined,
      rpc_ssl: data.rpc_ssl || undefined,
      rpc_host: data.rpc_host || undefined,
      rpc_port: data.rpc_port || undefined,
      rpc_path: data.rpc_path || undefined,
    };

    updateNet({ variables: { input: payload } }, true)
      .then(() => {
        openNotification({
          type: 'success',
          message: 'Net updated',
        });
        setOpen(false);
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: 'Error while updating net',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} title="Update net">
      <Space direction="vertical" style={{ width: '100%', paddingTop: 20, position: 'relative' }}>
        {loading && <LoaderOn />}

        <Space direction="vertical" style={{ width: '100%' }}>
          <Input
            label="Net ID"
            value={data.netID}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                netID: e.target.value,
              }))
            }
          />

          <Input
            label="Token Symbol"
            value={data.symbol}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                symbol: e.target.value,
              }))
            }
          />

          <Input
            label="Name"
            value={data.name}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />

          <Input
            label="Digits"
            value={data.digits}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                digits: parseInt(e.target.value, 10),
              }))
            }
          />

          <Space>
            <div>PRC SSL</div>
            <Switch
              value={data.rpc_ssl || false}
              onChange={(value) =>
                (data.rpc_host || data.rpc_port || data.rpc_path) &&
                setData((prev) => ({
                  ...prev,
                  rpc_ssl: value,
                }))
              }
            />
          </Space>

          <Input
            label="PRC Host"
            value={data.rpc_host}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                rpc_host: e.target.value,
              }))
            }
          />

          <Input
            label="PRC Port"
            value={data.rpc_port}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                rpc_port: parseInt(e.target.value, 10),
              }))
            }
          />

          <Input
            label="PRC Path"
            value={data.rpc_path}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                rpc_path: e.target.value,
              }))
            }
          />
        </Space>

        <Space style={{ width: '100%', justifyContent: 'space-between', marginTop: 20 }}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button type="primary" onClick={() => sendUpdateNet('api')} disabled={!data.netID}>
            Update
          </Button>
        </Space>
      </Space>
    </Modal>
  );
};

export default UpdateNetModal;
