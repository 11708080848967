import { gql } from '@apollo/client';
import { TOKEN } from 'fragments';

const GET_USER = gql`
  query ($id: ID!) @api(name: user) {
    user(id: $id) {
      id
      email
      first_name
      last_name
      created_at
      avatar
      invited_by_name
      ceo_of
      kyc
      role_id
      role_name
      roles {
        id
        name
      }
      roles_list
    }
  }
`;

const GET_CURRENT_USER = gql`
  query @api(name: user) {
    currentUser {
      id
      email
      first_name
      last_name
      avatar
      kyc
      role_id
      roles {
        id
        name
      }
      role_name
      roles_list
      market_id
    }
  }
`;

const GET_PRO_LOGIN = gql`
  ${TOKEN}
  mutation GetProLogin($input: LoginInput) @api(name: user) {
    login(input: $input) {
      ...TokenFields
      user {
        id
        market_id
        calculator_id
        email
        is_draft
        registration_role
        firstName: first_name
        lastName: last_name
      }
    }
  }
`;

const SEND_INVITE = gql`
  mutation ($input: SendInvitationInput!) @api(name: user) {
    sendInvitation(input: $input) {
      link
      code
      invitation {
        created_at
        expired_at
      }
    }
  }
`;

const ACCEPT_INVITE = gql`
  mutation AcceptInvitation($input: AcceptInvitationInput!) @api(name: user) {
    acceptInvitation(input: $input) {
      invitation {
        first_name
        last_name
      }
      url
    }
  }
`;

const CREATE_NEW_DIGITAL_INDIVIDUAL = gql`
  ${TOKEN}
  mutation CreateNewDigitalIndividual($input: RegisterIndividualInput) @api(name: user) {
    registerIndividual(input: $input) {
      tokens {
        ...TokenFields
        user {
          id
          email
          first_name
          last_name
          market_id
          calculator_id
        }
      }
      status
    }
  }
`;

const GET_URL_FOR_PUBLIC_UPLOAD = gql`
  mutation getPublicUrl($input: sFile) @api(name: user) {
    getUrlForPublicUpload(input: $input) {
      url
      download_url
    }
  }
`;

const GET_IDV_INIT = gql`
  mutation GetIdvInit($input: idvInitInput) @api(name: user) {
    idvInit(input: $input) {
      url
    }
  }
`;

const GET_USER_COMPANIES_LIST = gql`
  query ($user_id: String!) @api(name: market) {
    showAllCompaniesByUserId(user_id: $user_id) {
      id
      user_id
      company_name
      kyb_completed
      tags {
        id
        tag
      }
      logos {
        original_name
        path
        type
      }
    }
  }
`;

const GET_ACTIVE_USER_COMPANIES_LIST = gql`
  query @api(name: market) {
    showActiveUserCompanies {
      id
      user_id
      company_name
      kyb_completed
      logos {
        original_name
        path
        type
      }
    }
  }
`;

const GET_AGREEMENT = gql`
  query @api(name: user) {
    agreementPrivacyAndHonor {
      id
      field
      body
    }
  }
`;

const GET_VALIDATOR_AGREEMENT = gql`
  query @api(name: user) {
    agreementValidator {
      id
      field
      body
    }
  }
`;

const SIGN_VALIDATOR_AGREEMENT = gql`
  query @api(name: user) {
    signAgreementValidator {
      validator_agreement
    }
  }
`;

const GET_ROLE = gql`
  query @api(name: user) {
    roles {
      id
      name
    }
  }
`;

// Admin
const GET_ADMIN_USERS = gql`
  query ($input: UsersInput) @api(name: user) {
    adminUsers(input: $input) {
      count
      users {
        id
        first_name
        last_name
        email
        roles {
          id
          name
        }
        created_at
        invited_by_name
        kyc
        last_login_ip
        status
      }
    }
  }
`;
const GET_ADMIN_ROLES_COUNT = gql`
  query @api(name: user) {
    adminRolesCount {
      role
      count
    }
  }
`;
const UPDATE_USER_ROLE = gql`
  mutation ($input: UpdateUserRoleInput) @api(name: user) {
    updateUserRole(input: $input) {
      success
      user {
        id
        roles {
          name
        }
      }
    }
  }
`;

const UPDATE_USER_STATUS = gql`
  mutation ($input: UpdateUserStatusInput) @api(name: user) {
    updateUserStatus(input: $input) {
      success
      user {
        id
        status
      }
    }
  }
`;
const GET_NOTIFICATIONS = gql`
  query ($filter: String!) @api(name: user) {
    notifications(filter: $filter) {
      id
      read_at
      created_at
      user {
        id
        avatar
        first_name
        last_name
        email
      }
      message
      content {
        id
        body
      }
    }
  }
`;
const MARK_AS_READ_NOTIFICATION = gql`
  mutation ($id: ID!) @api(name: user) {
    markAsReadNotification(id: $id) {
      message
      status
    }
  }
`;
const DELETE_NOTIFICATION = gql`
  mutation ($id: ID!) @api(name: user) {
    deleteNotification(id: $id) {
      message
      status
    }
  }
`;

const FORGOT_USER_PASSWORD = gql`
  mutation ($input: ForgotPasswordInput!) @api(name: user) {
    forgotPassword(input: $input) {
      status
      message
    }
  }
`;

const CONFIRM_FORGOT_USER_PASSWORD = gql`
  mutation ($input: NewPasswordWithCodeInput!) @api(name: user) {
    updateForgottenPassword(input: $input) {
      status
      message
    }
  }
`;

const LOGOUT_USER = gql`
  mutation @api(name: user) {
    logout {
      status
      message
    }
  }
`;

export const GET_SHORT_COMPANY_BY_ID = gql`
  query ($id: String!) @api(name: market) {
    showCompanyById(id: $id) {
      id
      company_name
      logos {
        original_name
        path
        type
      }
    }
  }
`;

export const GET_USER_COMPANIES_SHORT_LIST = gql`
  query ($user_id: String!) @api(name: market) {
    showAllCompaniesByUserId(user_id: $user_id) {
      id
      company_name
      logos {
        original_name
        path
        type
      }
    }
  }
`;

export const CARBON_GET_LIST = gql`
  query GetAllUserCarbonCredit @api(name: market) {
    getAllUserCarbonCredit {
      id
      credit_id
      order_id
      serial_number
      project
      project_link
      smart_contract_address
      user_id
      quantity
      email
      wallet
      created_at
    }
  }
`;

export const CARBON_GET_DATA = gql`
  query GetUserCarbonCredit($id: String!) @api(name: market) {
    getUserCarbonCredit(id: $id) {
      id
      credit_id
      order_id
      serial_number
      project
      project_link
      smart_contract_address
      user_id
      quantity
      email
      wallet
      created_at
    }
  }
`;

export const CARBON_GET_OFFSET_DATA = gql`
  mutation GetAllOffsetData($input: OffsetCreditDataInput!) @api(name: market) {
    getAllOffsetData(input: $input) {
      id
      tx_hash
      user_id
      register_id
      email
      wallet
      parentCertificate {
        smart_contract_address
        project
        project_link
      }
      issued_by
      issued_for
      description
      date
      beneficiary
      calc_result_id
      quantity
    }
  }
`;

export const CARBON_OFFSET = gql`
  mutation Offset($input: OffsetCreditInput!) @api(name: market) {
    offset(input: $input) {
      id
      tx_hash
      user_id
      register_id
      email
      wallet
      parentCertificate {
        smart_contract_address
        project
        project_link
      }
      issued_by
      description
      date
      beneficiary
      calc_result_id
      quantity
    }
  }
`;

// register

export const CARBON_CREATE_REGISTER = gql`
  mutation CreateRegistry($input: RegistryInput!) @api(name: market) {
    createRegistry(input: $input) {
      id
      company_id
      user_id
      name
      description
      unit
      project_id
    }
  }
`;

export const CARBON_GET_REGISTER = gql`
  query GetRegistry($id: String!) @api(name: market) {
    getRegistry(id: $id) {
      id
      company_id
      user_id
      name
      description
      unit
      project_id
    }
  }
`;

export const CARBON_GET_REGISTERS = gql`
  query GetRegistries($company_id: String, $user_id: String) @api(name: market) {
    getRegistries(company_id: $company_id, user_id: $user_id) {
      id
      company_id
      user_id
      name
      description
      unit
      project_id
    }
  }
`;

export const CARBON_UPDATE_REGISTER = gql`
  mutation UpdateRegistry($input: RegistryInput!) @api(name: market) {
    updateRegistry(input: $input) {
      id
      company_id
      user_id
      name
      description
      unit
      project_id
    }
  }
`;

export const CARBON_DELETE_REGISTER = gql`
  mutation DeleteRegistry($id: String!) @api(name: market) {
    deleteRegistry(id: $id) {
      id
    }
  }
`;

export const CARBON_GET_REGISTER_ENTRY = gql`
  query GetRegistryItem($id: String!) @api(name: market) {
    getRegistryItem(id: $id) {
      id
      carbon_registry_id
      customers_id
      name
      email
      wallet_address
      amount
      status
    }
  }
`;

export const CARBON_GET_REGISTER_ENTRIES_BY_ID = gql`
  query GetRegistryItemsByRegistryId($carbon_registry_id: String!) @api(name: market) {
    getRegistryItemsByRegistryId(carbon_registry_id: $carbon_registry_id) {
      id
      carbon_registry_id
      customers_id
      name
      email
      wallet_address
      amount
      status
    }
  }
`;

export const CARBON_CREATE_REGISTER_ENTRY = gql`
  mutation CreateRegistryItem($input: RegistryItemInput!) @api(name: market) {
    createRegistryItem(input: $input) {
      id
      carbon_registry_id
      customers_id
      name
      email
      wallet_address
      amount
      status
    }
  }
`;

export const CARBON_UPDATE_REGISTER_ENTRY = gql`
  mutation UpdateRegistryItem($input: RegistryItemInput!) @api(name: market) {
    updateRegistryItem(input: $input) {
      id
      carbon_registry_id
      customers_id
      name
      email
      wallet_address
      amount
      status
    }
  }
`;

export const CARBON_DELETE_REGISTER_ENTRY = gql`
  mutation DeleteRegistryItem($id: String!) @api(name: market) {
    deleteRegistryItem(id: $id) {
      id
    }
  }
`;
// TODO
export const BC_ADMIN_UPDATE_NET = gql`
  mutation DeleteRegistryItem($id: String!) @api(name: market) {
    deleteRegistryItem(id: $id) {
      id
    }
  }
`;

export {
  GET_USER,
  LOGOUT_USER,
  GET_CURRENT_USER,
  GET_PRO_LOGIN,
  SEND_INVITE,
  CREATE_NEW_DIGITAL_INDIVIDUAL,
  GET_IDV_INIT,
  GET_USER_COMPANIES_LIST,
  GET_ACTIVE_USER_COMPANIES_LIST,
  GET_AGREEMENT,
  GET_ROLE,
  GET_ADMIN_USERS,
  GET_ADMIN_ROLES_COUNT,
  UPDATE_USER_ROLE,
  UPDATE_USER_STATUS,
  MARK_AS_READ_NOTIFICATION,
  DELETE_NOTIFICATION,
  GET_URL_FOR_PUBLIC_UPLOAD,
  FORGOT_USER_PASSWORD,
  CONFIRM_FORGOT_USER_PASSWORD,
  GET_VALIDATOR_AGREEMENT,
  SIGN_VALIDATOR_AGREEMENT,
  GET_NOTIFICATIONS,
  ACCEPT_INVITE,
};
