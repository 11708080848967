import React from 'react';
import { PersonalAreaBoxWrapper } from './components/PersonalAreaBox';

export const calculateLinks = (componentMode = 'main') => {
  const endpointMain = process.env.REACT_APP_MAIN || '';
  const endpointMarket = process.env.REACT_APP_MARKET || '';
  const endpointDigitalAssets = process.env.REACT_APP_DIGITAL_ASSETS || '';

  const redirectMain = (url) => `${endpointMain}${url}`;
  const redirectMarket = (url) => `${endpointMarket}${url}`;
  const redirectDigitalAssets = (url) => `${endpointDigitalAssets}${url}`;

  switch (componentMode) {
    case 'main':
      return {
        avatarMenu: [
          {
            href: redirectMarket('/dashboard/companiesList'),
            name: 'Company Profile',
            individualLink: redirectMarket('/dashboard/companyProfile'),
          },
          { href: '/dashboard/financials', name: 'Financials' },
        ],
        mainMenu: [
          {
            href: redirectMarket('/dashboard/marketplace'),
            name: 'Market',
          },
          {
            href: `/dashboard/score_and_reporting`,
            name: 'Score & Reporting',
          },
          {
            href: `/dashboard/tokenize`,
            name: 'Tokenize & Trace',
            disabled: true,
          },
          {
            href: `/dashboard/decarbonizator`,
            name: 'Decarbonizator',
          },
          {
            href: `/dashboard/notarizator`,
            name: 'Notarizator',
            disabled: true,
          },
          {
            href: redirectDigitalAssets('/dashboard/digital_assets'),
            name: 'Digital Assets',
          },
          {
            href: `/dashboard/compliance`,
            name: 'Compliance',
            disabled: true,
          },
          {
            href: `/dashboard/archived`,
            name: 'API & Integration',
            disabled: true,
          },
        ],
      };

    case 'DA':
      return {
        avatarMenu: [
          {
            href: redirectMarket('/dashboard/companiesList'),
            name: 'Company Profile',
            individualLink: redirectMarket('/dashboard/companyProfile'),
          },
          { href: redirectMain('/dashboard/financials'), name: 'Financials' },
        ],
        mainMenu: [
          {
            href: redirectMarket('/dashboard/marketplace'),
            name: 'Market',
          },
          {
            href: redirectMain('/dashboard/score_and_reporting'),
            name: 'Score & Reporting',
          },
          {
            href: redirectMain(`/dashboard/tokenize`),
            name: 'Tokenize & Trace',
            disabled: true,
          },
          {
            href: redirectMain(`/dashboard/decarbonizator`),
            name: 'Decarbonizator',
          },
          {
            href: redirectMain(`/dashboard/notarizator`),
            name: 'Notarizator',
            disabled: true,
          },
          {
            href: '/dashboard/digital_assets',
            name: 'Digital Assets',
          },
          {
            href: redirectMain(`/dashboard/compliance`),
            name: 'Compliance',
            disabled: true,
          },
          {
            href: redirectMain(`/dashboard/saved-folders`),
            name: 'API & Integration',
            disabled: true,
          },
        ],
      };

    case 'market':
      return {
        avatarMenu: [
          {
            href: '/dashboard/companiesList',
            name: 'Company Profile',
            individualLink: '/dashboard/companyProfile',
          },
          { href: redirectMain('/dashboard/financials'), name: 'Financials' },
        ],
        mainMenu: [
          {
            href: '/dashboard/marketplace',
            name: 'Market',
          },
          {
            href: redirectMain('/dashboard/score_and_reporting'),
            name: 'Score & Reporting',
          },
          {
            href: redirectMain(`/dashboard/tokenize`),
            name: 'Tokenize & Trace',
            disabled: true,
          },
          {
            href: redirectMain(`/dashboard/decarbonizator`),
            name: 'Decarbonizator',
          },
          {
            href: redirectMain(`/dashboard/notarizator`),
            name: 'Notarizator',
            disabled: true,
          },
          {
            href: redirectDigitalAssets('/dashboard/digital_assets'),
            name: 'Digital Assets',
          },
          {
            href: redirectMain(`/dashboard/compliance`),
            name: 'Compliance',
            disabled: true,
          },
          {
            href: redirectMain(`/dashboard/saved-folders`),
            name: 'API & Integration',
            disabled: true,
          },
        ],
      };

    case 'other':
      return {
        avatarMenu: [
          {
            href: '/dashboard/companiesList',
            name: 'Company Profile',
            individualLink: '/dashboard/companyProfile',
          },
          { href: redirectMain('/dashboard/financials'), name: 'Financials' },
        ],
        mainMenu: [
          {
            href: redirectMarket('/dashboard/marketplace'),
            name: 'Market',
          },
          {
            href: redirectMain('/dashboard/score_and_reporting'),
            name: 'Score & Reporting',
          },
          {
            href: redirectMain(`/dashboard/tokenize`),
            name: 'Tokenize & Trace',
            disabled: true,
          },
          {
            href: redirectMain(`/dashboard/decarbonizator`),
            name: 'Decarbonizator',
          },
          {
            href: redirectMain(`/dashboard/notarizator`),
            name: 'Notarizator',
            disabled: true,
          },
          {
            href: redirectDigitalAssets('/dashboard/digital_assets'),
            name: 'Digital Assets',
          },
          {
            href: redirectMain(`/dashboard/compliance`),
            name: 'Compliance',
            disabled: true,
          },
          {
            href: redirectMain(`/dashboard/saved-folders`),
            name: 'API & Integration',
            disabled: true,
          },
        ],
      };

    default:
      return [];
  }
};

export const PersonalAreaBox = ({
  user,
  pathname,
  firstNameProps,
  lastNameProps,
  avaProps,
  inviteProps,
  ceoProps,
  kycProps,
  mode = 'main', // 'main' | 'DA' | 'market'
  ...restProps
}) => {
  const links = calculateLinks(mode) || [];

  return (
    <PersonalAreaBoxWrapper
      user={user}
      pathname={pathname}
      firstNameProps={firstNameProps}
      lastNameProps={lastNameProps}
      avaProps={avaProps}
      inviteProps={inviteProps}
      ceoProps={ceoProps}
      kycProps={kycProps}
      links={links}
      {...restProps}
    />
  );
};
